<template>
  <IonPage>
    <IonContent>
      <ion-card>
        <ion-card-content style="width:fit-content;margin:auto">
          <IonGrid>
            <ion-row>
              <IonCol>Rooms</IonCol>
              <ion-col class="align-right">{{formatAmount(tour.roomsTotal)}}</ion-col>
              <ion-col size="6">
              </ion-col>
            </ion-row>
            <ion-row>
              <IonCol>Options</IonCol>
              <ion-col class="align-right">{{formatAmount(tour.optionsPrice)}}</ion-col>
              <ion-col size="6">
              </ion-col>
            </ion-row>
            <ion-row>
              <IonCol>VAT @ 20%</IonCol>
              <ion-col class="align-right">{{formatAmount(vatTotal())}}</ion-col>
              <ion-col size="6">
              </ion-col>
            </ion-row>
            <ion-row>
              <IonCol>Total</IonCol>
              <ion-col class="align-right">{{formatAmount(total())}}</ion-col>
              <ion-col size="6">
              </ion-col>
            </ion-row>

            <ion-row>
              <IonCol>Deposit Payable Today</IonCol>
              <ion-col class="align-right">{{formatAmount(totalDeposit())}}</ion-col>
              <ion-col size="6">
              </ion-col>
            </ion-row>

            <IonRow style="min-width:600px;">
              <IonCol>
                <div class="spacer">
                  We accept all major cards from around the world!
                </div>
                <div class="spacer">
                  <IonImg style='width:200px;' src='/assets/images/creditcards.png' />
                </div>
                <div id="stripe"></div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton color="btn" @click='payNow' v-if='canPay'>Pay Now</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <spinner :enabled="spin"></spinner>
          <ion-button ref="openModal" style='visibility:hidden' id="open-modal" expand="block">Open</ion-button>
          <ion-modal  trigger="open-modal" ref="errorModal" id="error-modal">
            <div style="width:100%;margin:auto;text-align: center;padding:10px;">
              <h2>Payment Could Not Be Processed</h2>
              <h4>{{errorMessage}}</h4>
              <ion-button id="dismiss-error" @click="closeModal">ok</ion-button>
            </div>
          </ion-modal>
        </ion-card-content>
      </ion-card>

    </IonContent>
  </IonPage>
</template>

<style scoped>
h2 {
  color:red;
  margin-bottom:20px;
  text-align:center;
}

h4 {
  text-align: start;
}

#stripe {
  padding: 10px
}

.margins-left-right {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.spacer {
  margin-bottom: 10px;
}

#error-modal {
  --backdrop-opacity: .5;
  --border-color: grey;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 5px;
}

#dismiss-error {
  position:absolute;
  bottom:20px;
}

.align-right {
  text-align: right;
}
</style>

<script setup>
import { ref, watch } from 'vue';
import { useIonRouter } from '@ionic/vue'

import spinner from '@/components/Spinner.vue';
import useHeader from '@/composables/useHeader';
import useTours from '@/composables/useTours';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonImg, IonButton, onIonViewDidEnter, IonCard, IonCardContent, IonCardHeader, IonModal } from '@ionic/vue';
import { loadStripe } from '@stripe/stripe-js';
import tourcms from '../tourcms';
import useStripe from '@/composables/useStripe';

const router = useIonRouter();

const stripeToken =
  'pk_live_c8nWDygQw3HVmjcJ40ONSWQX00YhXWxwQL';
// 'pk_test_51JSvxSJYkF8FswiaSZ9N5vXOvUs9w93xMhpGlNfxVyyoYJ7YDVxiRzV11VP6U2LTFfI4erUvy6OfOA7C82YPYM1200mESmPOoP';

const tours = useTours();
const tour = tours.tour;
const header = useHeader();


const canPay = ref(false);
const spin = ref(false);
const errorMessage = ref('');
const openModal = ref(null);
const errorModal = ref(null);
let stripe;
let $stripe = useStripe(true);
let elements;
let card;
let cardBrand;

onIonViewDidEnter(() => {
  header.setText('Checkout');
  console.log('Checkout', tours.tour);
});

function displayError() {
  openModal.value.$el.click();
}

function closeModal() {
  errorModal.value.$el.dismiss();
}
function getFontSize() {
  const fontSize = '20px';

  return fontSize;
}
async function setupStripe() {
  /* eslint-disable*/
  stripe = await loadStripe(stripeToken);
  elements = stripe.elements();
  card = elements.create('card', {
    style: {
      base: {
        color: '#32325D',
        backgroundColor: '#f7f7f7',
        fontWeight: 500,
        fontFamily: 'Inter UI, Open Sans, Segoe UI, sans-serif',
        fontSize: getFontSize(),
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF'
        }
      },
      invalid: {
        color: '#E25950'
      }
    }
  });
  card.addEventListener('change', ({ error }) => {
    if (error) {
      debugger;
    }
  });
  card.addEventListener('change', event => {
    if (event.complete) {
      cardBrand = event.brand;
      canPay.value = true;
    } else if (event.error) {
      canPay.value = false;
    }
  });
  card.mount('#stripe');
}

setupStripe().then(() => null);

tours.getBookingKey().then(bookingKey => {
  const customers = [tour.customer, ...tour.additionalTravellers].map(c => ({ customer: c }));
  return tourcms.startNewBooking(bookingKey, customers, tours.tour.components);
}).then(response => {
  tour.bookingId = response.booking.booking_id;
});

function vatTotal() {
  return (tour.roomsTotal + tour.optionsPrice) * .2;
}

function total() {
  return tour.roomsTotal + tour.optionsPrice + vatTotal();
}

function totalDeposit() {
  return total() * .2;
}

function payNow() {
  spin.value = true;
  $stripe.createPaymentIntent(this.totalDeposit(), 'gbp', 'card').then(intent => {
    return stripe.confirmCardPayment(intent.client_secret, {
      payment_method: {
        card
      }
    });
  }).then(confirmation => {
    if (confirmation.error) {
      throw confirmation.error;
    }
    tour.receipt = {
      referenceNumber: tour.bookingId,
      paymentMethod: `${cardBrand} ${confirmation.paymentIntent.payment_method_types[0]}`,
      paymentAmount: (confirmation.paymentIntent.amount / 100).toFixed(2)
    }
    return tours.commitBooking(tour.bookingId);
  }).then(response => {
    spin.value = false;

    if (response.error === 'OK') {
      return addRoomNote();
    } else {
      debugger;
    }
  }).then(() => {
    router.push('/Receipt');
  }).catch(error => {
    spin.value = false;

    if (error.message) {
      errorMessage.value = error.message;
    } else {
      errorMessage.value = 'Your transaction could not be completed, please contact support.'
    }
    displayError();
  });
}

function formatAmount(amount) {
  return (amount / 100).toFixed(2);
}

function bookingInfo() {
  let bookString = 'ROOM CONFIGS - ';

  if (tour.rooms) {
    tour.rooms.forEach(room => {
      bookString += 'ROOM = room type:' + room.type + ', ' + 'number of occupants:' + room.occupants + '  '
    });
  }

  return bookString;
}

async function addRoomNote() {
  return tours.addNoteToBooking(tours.tour.bookingId, 'SERVICE', bookingInfo());
}
</script>

<template>
  <ion-page>
    <ion-content>
      <div class="margins-left-right">
        <h3>Rooms</h3>
        <ion-item v-for='room in tour.rooms' :key='room.id' :id='room.id'>
          <select-room :room='room' :tour='tour' @room-changed='roomChanged' @delete-room='deleteRoom' />
        </ion-item>
        <ion-item>
          <ion-button style='padding-left:7px;' color='btn' @click='addRoom'>Add Room</ion-button>
        </ion-item>
        <h3>Dates and Prices</h3>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-card>
                <ion-card-header>
                  <ion-card-title>Departure Date</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  {{tours.tour.date.start_date}}
                </ion-card-content>
                  
                </ion-card>
                <ion-card>
                <ion-card-header>
                  <ion-card-title>Return Date</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  {{tours.tour.date.end_date}}
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col>
              <ion-card>
                <ion-card-header>
                  <ion-card-title>Price</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-text>
                    Price per person £{{ formatAmount(perPersonTotal) }} + VAT, 
                    Total = £{{ formatAmount(tourTotal) }} + VAT
                  </ion-text>
                </ion-card-content>
              </ion-card>
              <ion-card>
                <ion-card-header>
                  <ion-card-title>Deposit</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ion-text>
                    {{deposit * 100}}% deposit £{{formatAmount(tourTotal * deposit)}}
                  </ion-text>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
        <spinner :enabled="spin"></spinner>
        <div v-if='availability && tour.options.length'>
          <ion-button size='small' color='btn' @click='selectOptions'>Add Options</ion-button>
        </div>
        <div v-else-if='availability'>
          <ion-button size='small' color='btn' @click='customerDetails'>Customer Details</ion-button>
        </div>

        <ion-alert :is-open="overMaxAlert" header="Maximum Booking Exceeded"
          message='`Maximum booking size is ${details.value.max_booking_size}. You have selected booking for ${totalPersons.value} people.`'
          :buttons="['OK']" @didDismiss="() => overMaxAlert = false" />
      </div>
    </ion-content>
  </ion-page>

</template>

<style scoped>
.margins-left-right {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
</style>
<script setup>

import { ref, computed } from 'vue';
import { useIonRouter } from '@ionic/vue';
import useHeader from '@/composables/useHeader';
import useTours from '@/composables/useTours';
import {
  IonPage,
  IonContent,
  IonList,
  IonListHeader,
  IonItem,
  IonText,
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  onIonViewDidEnter
} from '@ionic/vue';
import SelectRoom from '@/components/SelectRoom';
import Spinner from '@/components/Spinner';
import { formatAmount } from '@/util';
//
const tours = useTours();
const details = ref({});
const ionRouter = useIonRouter();
const header = useHeader();
const availability = ref(false);
const options = ref([]);
const overMaxAlert = ref(false);
const tour = tours.tour;
const spin = ref(false);

const deposit = 0.2;



onIonViewDidEnter(() => {
  console.log('Select Rooms', tours.tour);

  header.setText('Select Rooms');
  if (tour.rooms.length === 0) {
    addRoom();
  }
});

function checkAvailability() {
  spin.value = true;
  tours.checkAvailability(tour.id, tour.date.start_date, totalPersons.value).then(rval => {
    spin.value = false;
    availability.value = rval;
  }).catch(error => {
    console.log(error);
    debugger;
    alert(error);
    ionRouter.push('/');
  });
  overMaxAlert.value = totalPersons.value > details.value.max_booking_size;
}

const tourTotal = computed(() => {
  return totalPersons.value * perPersonTotal.value;
});

const perPersonTotal = computed(() => {
  return tour.date.price_1 * 100;
});

const totalPersons = computed(() => {
  if (!tour.rooms.length) {
    return 0;
  }
  return tours.tour.rooms.reduce((a, r) => (a += r.occupants), 0);
});

function addRoom() {
  tour.rooms.push({
    id: tour.rooms.length + 1,
    type: 'Single',
    occupants: 1
  });
  checkAvailability();
}

function deleteRoom(id) {
  const index = tour.rooms.findIndex(r => r.id === id);

  if (index >= 0) {
    let n = 1;
    tour.rooms.splice(index, 1);
    tour.rooms.forEach(r => (r.id = n++));
  }
  checkAvailability();
}

function roomChanged(room) {
  tour.rooms[room.id - 1] = room;
  checkAvailability();
}


function bookNow() {
  tour.price = tourTotal.value;
  ionRouter.push('/CustomerDetails');
}

function selectOptions() {
  tour.price = tourTotal.value;
  tour.roomsTotal = tourTotal.value;
  tour.totalPersons = totalPersons;
  ionRouter.push('/SelectOptions');
}

function customerDetails() {
  tour.price = tourTotal.value;
  tour.roomsTotal = tourTotal.value;
  tour.totalPersons = totalPersons;
  ionRouter.push('/CustomerDetails');
}

</script>

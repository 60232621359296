<template>
  <ion-page>
    <ion-content fullscreen>
      <ion-grid>
        <ion-row>
          <ion-col>
          </ion-col>
          <ion-col>
            <ion-card>
              <ion-card-header>
                <div style="text-align: center; color: #e9bb51;font-size:larger;font-style:bold;">
                  <div>{{tour.details?.tour_name}}</div>
                  <div v-if="tour.date">
                    {{tour.date.start_date}} through {{tour.date.end_date}}
                  </div>
                </div>
              </ion-card-header>
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col>
                    </ion-col>
                    <ion-col>
                      <div style="width:fit-content;text-align:center;margin:auto;margin-top:20px;">
                        <ion-datetime color='cal' @ion-change='dateSelected' ref='datetime' presentation='date'
                          :isDateEnabled='date => isDateEnabled(date)' :value='selectedDate' :min='minDate'
                          :max='maxDate' multiple='false'>
                          <span slot="title">Select a Tour Date</span>
                        </ion-datetime>
                        <ion-button color='btn' v-if='tour && datesIndex < tour.datesAndPrices.length - 1'
                          @click='nextAvailable' size='small'>next
                          available
                          date</ion-button>
                        <ion-button color='btn' v-if='datesIndex' @click='prevAvailable' size='small'>prev available
                          date
                        </ion-button>
                        <br />
                      </div>
                    </ion-col>
                    <ion-col>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col></ion-col>
        </ion-row>
      </ion-grid>
      <div>
        <div>
          <ion-grid>
            <ion-row>
              <ion-col></ion-col>
              <ion-col>
                <ion-card v-if='needsRooms()'>
                  <div style="margin:auto;width:fit-content;">
                    <ion-button color='btn' @click='selectRooms' size='small'>Select Rooms
                    </ion-button>

                  </div>
                </ion-card>
                <ion-card v-if='!needsRooms()'>
                  <ion-card-content>
                    <ion-item>
                      <ion-label style="font-size:larger" color='cal' position="floating">{{numberOfPeople()}}
                      </ion-label>
                      <ion-input style="font-size:larger" @ion-change="peopleChanged" v-model='tour.totalPersons'
                        color='cal' type="number" :min='tour.details.min_booking_size'
                        :max="tour.details.max_booking_size"></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-button v-if='tour.options.length' color='btn' @click='selectOptions' size='small'>Select Options</ion-button>
                      <ion-button v-else color='btn' @click='customerDetails' size='small'>Customer Details</ion-button>
                    </ion-item>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-content>
  </ion-page>

</template>

<style scoped>
.datetime-header {
  color: #6aa96a;
}
</style>
<script setup>
import { ref } from 'vue';
import useTours from '@/composables/useTours';
import useHeader from '@/composables/useHeader';
import { useIonRouter, onIonViewDidEnter } from '@ionic/vue'
import { IonGrid, IonRow, IonCol, IonPage, IonDatetime, IonButton, IonContent, IonCard, IonCardContent, IonCardHeader, IonItem, IonInput, IonLabel } from '@ionic/vue';

const tours = useTours()
const ionRouter = useIonRouter()
const datetime = ref(null)
const selectedDate = ref('')
const minDate = ref('2020-01-01')
const maxDate = ref('2120-12-22');
const header = useHeader();
const datesIndex = ref(0);
const available = ref(false);
let tour = tours.tour;

if (tour && !tour.id) {
  ionRouter.push('/');
}

onIonViewDidEnter(async () => {
  console.log('SelectDate', tours);
  header.setText('Select Date');
  if (!tour.totalPersons) {
    tour.totalPersons = 1;
  }
  jumpToFirst();
  // if(!needsRooms()) {
  //   checkAvailability();
  // }
});

function needsRooms() {
  return tour.date && tour.date.start_date !== tour.date.end_date;
}

function numberOfPeople() {
  return `Number Of People In Tour (${tour.details.min_booking_size} - ${tour.details.max_booking_size})`
}

function peopleChanged() {
  checkAvailability().then(available => {
    tour.price = tour.totalPersons * tour.date.price_1 * 100
  });
}
// eslint-disable-next-line
function isDateEnabled(date) {
  if (!tour.datesAndPrices) {
    return false;
  }
  return !!tour.datesAndPrices.find(d => date === d.start_date);
}

//
// TODO this isn't quite right because there might not be any remaining availablity
//
function jumpToFirst() {
  selectDate(datesIndex.value = 0);
}

function selectDate(index) {
  if (tour.datesAndPrices) {
    datetime.value.$el.reset(tour.datesAndPrices[index].start_date);
    selectedDate.value = tour.datesAndPrices[index].start_date;
  }
}

function nextAvailable() {
  selectDate(++datesIndex.value);
}

function prevAvailable() {
  selectDate(--datesIndex.value);
}
function dateSelected(event) {
  const date = event.detail.value.split('T')[0];

  tour.date = tour.datesAndPrices.find(d => date === d.start_date);
  selectedDate.value = date;
}

function selectRooms() {
  ionRouter.push('/SelectRooms');
}

async function checkAvailability() {
  return tours.checkAvailability(tour.id, tour.date.start_date, tour.totalPersons).then(rval => {
    return available.value = rval;
  }).catch(error => {
    console.log(error);
    debugger;
    alert(error);
    ionRouter.push('/');
  });
  // overMaxAlert.value = totalPersons.value > details.value.max_booking_size;
}

function customerDetails() {
  tour.optionsPrice = 0;

  ionRouter.push('/CustomerDetails');
}

function selectOptions() {
  tour.optionsPrice = 0;

  ionRouter.push('/SelectOptions');
}
</script>

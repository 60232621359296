/* eslint-disable */
import cache from "./cache";

const TourCMSApi = require("tourcms");

const tourcms = new TourCMSApi({
  channelId: 10176,
  apiKey: "9dca85ec869f",
  marketplaceId: "0",
});

async function execute(func, parameters = {}) {
  return new Promise((resolve, reject) => {
    parameters.callback = response => {
      if (response.error !== "OK") {
        reject(response);
      } else if (response.error === 'NO MATCHING DATA') {
        resolve(response);
      } else {
        resolve(response);
      }
    }
    tourcms[func](parameters);
  });
}
export default {
  get: async () => {
    return execute('searchTours');
  },
  getDates: async (id) => {
    return execute('showTourDatesDeals', {
      channelId: 10176,
      tourId: id,
      qs: {
        distinct_start_dates: 1
      },
    });
  },
  getDetails: async id => {
    return execute('showTour', {
      tourId: id
    });
  },
  checkAvailability: async (id, date, r1) => {
    return execute('checkTourAvailability', {
      qs: {
        id,
        date,
        r1
      },
    });
  },
  getRedirect: async url => {
    return execute('getBookingRedirectUrl', { url });
  },
  startNewBooking: (key, customers, components) => {
    return execute('startNewBooking', {
      booking: {
        booking_key: key,
        total_customers: customers.length,
        customers,
        components: components.filter(component => component.component_key)
      }
    });
  },
  commitBooking: id => {
    return execute('commitBooking', {
      booking: {
        booking_id: id,
        suppress_email: 1
      }
    });
  },
  addNoteToBooking(id, type, text) {
    return execute('addNoteToBooking', {
      booking: {
        booking_id: id,
        note: {
            type,
            text
        }
      }
    });
  }
}

import { createRouter, createWebHistory } from '@ionic/vue-router';
import SelectTour from '@/views/SelectTour';
import SelectDate from '@/views/SelectDate';
import SelectRooms from '@/views/SelectRooms';
import SelectOptions from '@/views/SelectOptions';
import CustomerDetails from '@/views/CustomerDetails';
import Checkout from '@/views/Checkout';
import Receipt from '@/views/Receipt';

const routes = [
  {
    path: '/',
    name: 'SelectTour',
    component: SelectTour
  },
  {
    path: '/SelectDate',
    name: 'SelectDate',
    component: SelectDate
  },
  {
    path: '/SelectRooms',
    name: 'SelectRooms',
    component: SelectRooms
  },
  {
    path: '/SelectOptions',
    name: 'SelectOptions',
    component: SelectOptions
  },
  {
    path: '/CustomerDetails',
    name: 'CustomerDetails',
    component: CustomerDetails
  },
  {
    path: '/Checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/Receipt',
    name: 'Receipt',
    component: Receipt
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <ion-app id='app-wrapper' v-if="splash">
    <ion-grid class="splash">
      <ion-row style="background-color:white;">
        <ion-col>
          <img src='/assets/images/spirit-journeys-logo-3-1.png' />
        </ion-col>
      </ion-row>
      <ion-row>
          <ion-col>
            <ion-grid>
              <ion-row style="height:400px;margin-top:150px;">
                <ion-col>
                  <div style="color:white;">
                    <h1>Spirit Journeys Booking App</h1>
                    <h1>Fast and efficent booking onto our great range of tours</h1>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-button color='btn' @click="splashed">Book Now</ion-button>

                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
        </ion-row>
    </ion-grid>
  </ion-app>
  <ion-app v-else id='app-wrapper'>
    <ion-header>
      <ion-toolbar>
        <ion-buttons style='height:60px' slot="start">
          <ion-back-button v-if='header.text !== ""' defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-grid>
          <ion-row>
            <ion-col>
            </ion-col>
            <ion-col size='8' style="text-align:center">
              <ion-title style='height:60px'>
                {{header.text}}
              </ion-title>
            </ion-col>
            <ion-col>
              <img style='height:60px; float:right' src='/assets/images/spirit-journeys-logo-3-1.png' />
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-router-outlet />
    </ion-content>
  </ion-app>

</template>


<script setup>

import { ref } from 'vue';

import useHeader from '@/composables/useHeader';
import {
  IonApp,
  IonRouterOutlet,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue'

const splash = ref(true);
const header = useHeader();

header.text = '';

function splashed() {
  splash.value = false;
}
</script>

<style>
#app-wrapper {
  height: 100%;
  overflow: scroll;
}

.splash {
  width:100%;
  margin:0px;
  padding:0px;
  background-image: url('/public/assets/images/splash.jpg');
  text-align: center;
}


/* .clicker {
  position: absolute;
  bottom: 0;
} 
*/
</style>

import { ref, reactive } from "vue";

const text = ref("");

function setText(s) {
  text.value = s;
}

export default function useHeader() {
  return reactive({
    text,
    setText
  });
}

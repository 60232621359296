import tourcms from "@/tourcms";

export default async function tourFactory(init) {

    let tour = {
        id: init.tour_id,
        date: undefined,
        selectedOptions: [],
        rooms: [],
        additionalTravellers: [],
        price: 0.0,
        deposit: 0.0,
        optionsPrice: 0.0,
        details: undefined,
        datesAndPrices: init.dates_and_prices.date,
        staffNote: undefined,
        options: [],
        totalPrice: (format = false) => {
            const price = this.price + this.optionsPrice;

            if (format) {
                return this.formatAmount(price);
            }
            return price;
        },
        totalVatPrice: (format = false) => {
            const vat = this.totalPrice() + this.vatAmount();

            if (format) {
                return this.formatAmount(vat);
            }
            return vat;
        },
        vatAmount: (format = false) => {
            const vat = this.totalPrice() * .2;

            if (format) {
                return this.formatAmount(vat);
            }
            return vat;
        },
        formatAmount: amount => {
            return (amount / 100).toFixed(2)
        },
    }

    Object.defineProperty(tour, 'total', {
        get() {
            return tour.price + tour.optionsPrice;
        }
    });

    Object.defineProperty(tour, 'vat', {
        get() {
            return tour.total * .2;
        }
    });

    Object.defineProperty(tour, 'deposit', {
        get() {
            return tour.total * .2;
        }
    });
    async function getDetails() {
        return tourcms.getDetails(tour.id);
    }
    //
    // add details
    //
    let details = await tourcms.getDetails(tour.id);

    tour.details = details.tour;
    tour.staffNote = tour.details.staff_note || '';

    return tour;
}
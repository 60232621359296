// stripeApiToken: 'pk_live_c8nWDygQw3HVmjcJ40ONSWQX00YhXWxwQL',
// below is my test account --- zt
const testToken ='sk_test_51JSvxSJYkF8FswiapIVse6dls0feFeebpsotsMr7LTAi7rpl87XbnYWPuw3fRAbzXxk4MXZDEk8JmhlY3D6GsbRo00ag8xuzWT';
const liveToken = 'sk_live_qetVTegBBf6bN27mkxcx0DKA001BC3NCPP';

let apiToken = liveToken;

async function createPaymentIntent(amount, currency, method) {
    const params =  new URLSearchParams({
        amount,
        currency,
        'payment_method_types[]': 'card'
    });
    return fetch(`https://api.stripe.com/v1/payment_intents?${params}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${apiToken}`
        }
    }).then(response => {
        return response.json();
    })
}

export default function useStripe(test = false) {
    // if(test) {
    //     apiToken = testToken;
    // }
    return {
        createPaymentIntent
    }
}
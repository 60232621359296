<template>
  <IonPage>
    <IonContent>
      <ion-card>
        <ion-card-header>
          <ion-card-title>Deposit Payment Receipt</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-row>
            <ion-col>Reference Number</ion-col>
            <ion-col>{{tours.tour.receipt.referenceNumber}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              Payment Method
            </ion-col>
            <ion-col>
              {{tours.tour.receipt.paymentMethod}}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>Payment Amount</ion-col>
            <ion-col>{{tours.tour.receipt.paymentAmount}}</ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
      <ion-button color="btn" @click='selectTour' style="margin-left:10px">Select Another Tour</ion-button>
    </IonContent>
  </IonPage>

</template>

<script setup>
import { useIonRouter } from '@ionic/vue';
import useTours from '@/composables/useTours';
import useHeader from '@/composables/useHeader';

import { IonPage, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonRow, IonCol, IonButton } from '@ionic/vue';

const tours = useTours();
const header = useHeader();
const ionRouter = useIonRouter();

header.setText('')

function selectTour() {
  ionRouter.push('/');
}
</script>

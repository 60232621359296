import { ref, reactive } from "vue";
import tourcms from "@/tourcms";
import tourFactory from './tour';

const all = ref([]);
const tour = ref({});
const dates = ref([]);
const rooms = ref([]);
//
// test
//
async function get(id) {
  if (id) {
    const toor = all.value.find((t) => t.tour_id === id);

    return tourcms.getDates(toor.tour_id).then( async response => {
      tour.value = toor;
      if (response.error !== "NO MATCHING DATA") {
        dates.value = response.dates_and_prices.date;
      } else {
        dates.value = [];
      }
      tour.value = await tourFactory(response);

      console.log('setting tour', tour.value);
      return tour.value;
    });
  }
  return tourcms.get().then((response) => {
    all.value = response.tour;
    return all.value;
  });
}

async function getDetails(id) {
  return tourcms.getDetails(id);
}

async function checkAvailability(id, date, n) {
  return tourcms.checkAvailability(id, date, n).then(rval => {
    let available;

    tour.value.components = rval.available_components.component;
    available = tour.value.components.length;

    if(available) {
      tour.value.options = tour.value.components[0].options.option;
    }
    return available;
  });
}

//
// NB currently not used because getBookingKey is not used
//
async function getBookingRedirectUrl(redirectTo) {
  return tourcms
    .getRedirect(redirectTo)
    .then((response) => {
      console.log(response);
      debugger;
      return response;
    });
}

//
// NB currently not used ... there is a cors issue if this is ever needed
// the request must be passed through a server when running in the browser
//
async function getBookingKey(redirectedTo) {
  return 'NO_AGENT';
  // fetch(redirectedTo, {
  //   method: "GET",
  //   redirect: 'manual',
  // })
  //   .then((response) => {
  //     return response.headers.get("location");
  //   })
  //   .catch((err) => {
  //     console.log(error);
  //     debugger;
  //   });
}

async function startNewBooking(key, totalCustomers, components) {
  return tourcms.startNewBooking(key, totalCustomers, components);
}


async function commitBooking(bookingId) {
  return tourcms.commitBooking(bookingId);
}

async function addNoteToBooking(bookingId,type, text) {
  return tourcms.addNoteToBooking(bookingId, type, text);
}

export default function useTours() {
  return reactive({
    get,
    getDetails,
    getBookingRedirectUrl,
    getBookingKey,
    checkAvailability,
    startNewBooking,
    commitBooking,
    addNoteToBooking,
    all,
    tour,
    dates,
    rooms,
  });
}

<template>

  <ion-page>
    <ion-content>
      <div class="margins-left-right">
        <form @submit='paynow'>
          <h5>Customer Details</h5>
          <ion-card>
            <ion-card-content>

              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">First Name</ion-label>
                      <ion-input required type='text' v-model="tour.customer.firstName"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Last Name</ion-label>
                      <ion-input required type='text' v-model="tour.customer.surname"></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <!--        Line 2-->
                <ion-row>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Birthday</ion-label>
                      <ion-input required type='date' v-model="tour.customer.dob"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">E-Mail</ion-label>
                      <ion-input required type='email' placeholder="" v-model="tour.customer.email"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item>
                      <ion-label required position="floating">Telephone</ion-label>
                      <ion-input placeholder="" v-model="tour.customer.tel_home"></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">House Name/No.</ion-label>
                      <ion-input required placeholder="" v-model="tour.customer.no"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Street</ion-label>
                      <ion-input required placeholder="" v-model="tour.customer.street"></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <!--       Address Line 2-->
                <ion-row>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Address Line 3</ion-label>
                      <ion-input placeholder="" v-model="tour.customer.line3"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Town/City</ion-label>
                      <ion-input required placeholder="" v-model="tour.customer.city"></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <!--       Address Line 3-->
                <ion-row>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Postcode/Zip</ion-label>
                      <ion-input required placeholder="" v-model="tour.customer.postcode"></ion-input>
                    </ion-item>

                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>

          </ion-card>
          <!--            Additional Customer Details-->
          <h5 v-if='tour.additionalTravellers.length'>Additional Travellers Details</h5>
          <!-- // v-for number of bookings - 1 -->
          <ion-card>
            <ion-card-content>
              <ion-grid v-if='tour.additionalTravellers.length' v-for='traveller in tour.additionalTravellers'>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">First Name</ion-label>
                  <ion-input required placeholder="" v-model="traveller.firstName"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Last Name</ion-label>
                  <ion-input required placeholder="" v-model="traveller.surname"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Birthday</ion-label>
                  <ion-input required type='date' v-model="traveller.dob"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
            </ion-card-content>
          </ion-card>

          <ion-button color='btn' type='submit'>Checkout</ion-button>
        </form>
      </div>
    </ion-content>
  </ion-page>

</template>

<script setup>

import { ref, watch } from 'vue';
import { IonPage, IonContent, IonItem, IonLabel, IonInput, IonGrid, IonRow, IonCol, IonButton, IonCard, IonCardContent } from '@ionic/vue';
import { useIonRouter, onIonViewDidEnter } from '@ionic/vue';

import useHeader from '@/composables/useHeader';
import useTours from '@/composables/useTours';

const tours = useTours();
const tour = tours.tour;
const header = useHeader();
const router = useIonRouter();


onIonViewDidEnter(() => {
  console.log('CustomerDetails', tour);
  header.setText('Customer Details');
});

function setup() {
  let n = tour.totalPersons;

  if (!tour.customer) {
    tour.customer = {};
  }

  if (tour.rooms.length) {
    n = tour.rooms.reduce((a, r) => {
      a += r.occupants;
      return a;
    }, 0);
  }

  if (n-- === 1) {
    tour.additionalTravellers = [];
    return;
  }

  if (!tour.additionalTravellers.length) {
    tour.additionalTravellers = Array(tour.totalPersons - 1).fill({});
  }

  if (n < tour.additionalTravellers.length) {
    // remove travellers
    tour.additionalTravellers = tour.additionalTravellers.slice(n);
  }
  while (tour.additionalTravellers.length < n) {
    // add traveller
    tour.additionalTravellers.push({});
  }
  tour.additionalTravellers.forEach((t, i) => t.id = i);
}

function testSetup() {
  tour.customer = {
    firstName: 'x',
    surname: 'x',
    dob: '2012-08-08',
    email: 'x@y',
    tel_hom: 'x',
    no: 0,
    street: 'x',
    city: 'x',
    postcode: 'x'
  }
  tour.additionalTravellers = tour.additionalTravellers.map((t, i) => ({
    id: i,
    firstName: 'x',
    surname: 'x',
    dob: '2012-08-08'
  }))
}

function paynow(event) {
  event.preventDefault();
  router.push('Checkout');
}
setup();
testSetup();
</script>

<style scoped>
ion-label {
  line-height: 1;
}
input:invalid:required {
  background-image: linear-gradient(to right, pink, lightgreen);
}

.margins-left-right {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
</style>

<template>
  <ion-card>
    <ion-card-content>
      <ion-card-header>
        Room {{props.room.id}}
      </ion-card-header>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Room Type</ion-label>
              <ion-select :value='props.room.type' @ionChange='typeSelected($event)'>
                <ion-select-option v-for='c in config' :value="c.type">
                  {{c.type}}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col>
            <ion-item>
              <ion-label>
                Occupants
              </ion-label>
              <ion-select :value='props.room.occupants' ref='occupantsSelector' @ionChange='occupantsSelected'>
                <ion-select-option v-for='n in room.occ' :value="n">{{n}}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size="1">
            <ion-button size='small' color='danger' @click='deleteRoom(props.room.id)' v-if='props.room.id > 1'>
              X
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<style scoped>
  ion-item {
    float:left;
    min-width:400px;
  }
  ion-label {
    float:left;
  }
ion-card {
   width: 100%;
 }
</style>

<script setup>

import { ref, watch, defineProps, defineEmits } from 'vue';
import { IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, IonButton, IonLabel, IonItem } from '@ionic/vue'

const props = defineProps({
  room: Object,
  tour: Object
})

const emits = defineEmits(['room-changed', 'delete-room'])

function notify() {
  room.value.id = props.room.id
  emits('room-changed', room.value)
}
const room = ref(props.room)
const roomType = ref({})
const occupantsSelector = ref(null)

const config = [
  {
    type: 'Single',
    min: 1,
    max: 1,
    beds: 1,
    occ: [1]
  },
  {
    type: 'Double',
    min: 2,
    max: 2,
    beds: 1,
    occ: [1, 2]
  },
  {
    type: 'Twin',
    min: 2,
    max: 2,
    beds: 2,
    occ: [1, 2]
  },
  {
    type: 'Family',
    min: 3,
    max: 4,
    beds: 3,
    occ: [1, 2, 3, 4]
  }
]

if (room.value.type.value === '') {
  room.value = config[0];
}

if (props.tour.staffNote.toLowerCase() === 'twin-shared') {
  config.push({
    name: 'Twin Shared',
    min: 1,
    max: 1,
    beds: 1,
    occ: [1],
    roomNote: 'This is a room in a shared twin, allocated by the operator'
  })
}

if (!room.type) {
  room.value = config[0]
}

function typeSelected(event) {
  room.value = config.find(c => c.type === event.detail.value);
  // occupantsSelector.value.$el.value = 1; //room.value.occ[0];
  // notify();
}

function occupantsSelected(event) {
  room.value.occupants = event.detail.value
  notify()
}

function deleteRoom(id) {
  emits('delete-room', id);
}

</script>

<style scoped>
ion-select {
  justify-content: center;
  display: inline-block;
}
</style>

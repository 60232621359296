<template>
  <ion-page>
    <spinner :enabled="!(tours.all && tours.all.length)"></spinner>
    <div style='margin-top:20px;overflow:scroll'>
      <ion-grid>
        <ion-row v-for="(row, n) in rows">
          <ion-col size="4" v-for='(tour, i) in rows[n]'>
            <div  @click="selected(tour)">
              <ion-card class='tour-selector'>
                <ion-card-header style="text-align:center;">
                  <ion-card-title>
                    <ion-text v-if='tour.next_bookable_date !== ""' class='text-selector' color="cal">
                    <h4>{{tour.tour_name}}</h4>
                  </ion-text>
                  <ion-text v-else class='overflow-wrap' color="dark">
                    <h4 style="color:gainsboro">{{tour.tour_name}} - unavailable</h4>
                  </ion-text>

                  </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <img style='width:100%' :src="tour.thumbnail_image" />
                </ion-card-content>
              </ion-card>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-page>

</template>

<style scoped>
  h4 {
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
  }

.tour-selector {
  text-align: center;
  border-style: solid;
  border-width: 2;
  border-color: transparent;
  margin: auto;
}

.tour-selector:hover {
  border-style: solid;
  border-width: 2;
  border-color: #6aa96a;
}

.text-selector:hover {
  color: #6aa96a;
}

.white-text::part(native) {
  --ion-color-contrast: var(--ion-color-light);
}

.yellow-background::part(native) {
  background-color: #6aa96a;
}
</style>
<script setup>
import { ref } from 'vue';
import { useIonRouter, onIonViewDidEnter } from '@ionic/vue';
import useTours from '@/composables/useTours';
import useHeader from '@/composables/useHeader';
import Spinner from '@/components/Spinner';

import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonItem,
  IonThumbnail,
  IonSelect,
  IonSelectOption,
  IonPage,
  IonButton
} from '@ionic/vue'

const ionRouter = useIonRouter();
const tours = useTours();
const header = useHeader();
const rows = ref([]);

getTours();

// eslint-disable-next-line
onIonViewDidEnter(() => {
  header.setText('Select Tour');
  getTours();
});

function getTours() {
  tours.get().then(all => {
    const nrows = Math.floor((all.length + 2) / 3);

    all.forEach((t, i) => {
      const r = Math.floor(i / 3);
      const c = Math.floor(i % 3);

      if(c === 0) {
        rows.value.push([]);
      }

      rows.value[r][c] = t;
    });
  }).catch(errors => {
    debugger;
  });
}

function selected(tour) {
  if (tour.next_bookable_date !== '') {
    const thumbnail = tour.thumbnail_image;
    tours.get(tour.tour_id).then(tour => {
      tour.thumbnail = thumbnail;
      ionRouter.push('/SelectDate');
    });
  }
}
</script>

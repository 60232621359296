<template>
    <ion-page>
        <ion-content>
            <div class="margins-left-right">
                <h3>Options</h3>
                <IonItem>Selected Options Price {{formatAmount(tour.optionsPrice)}}</IonItem>
                <ion-list v-for='group in groups'>
                    <ion-list-header>
                        <h4>{{group.title}}</h4>
                    </ion-list-header>
                    <div v-for='option in group.options' :key='option.option_id'>
                        <ion-grid>
                            <ion-row>
                                <ion-col>
                                    <ion-item>
                                        <ion-label>
                                            {{option.option_name}}
                                        </ion-label>
                                        <ion-label>
                                            {{option.per_text}} {{getOptionPrice(option)}}

                                        </ion-label>
                                        <ion-label>
                                            <ion-checkbox @ion-change='optionChanged($event, option.option_id)' />
                                        </ion-label>
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </ion-list>
                <hr style="margin-top:20px" />
                <ion-button color='btn' @click='customerDetails'>Customer Details</ion-button>
            </div>
        </ion-content>
    </ion-page>

</template>
  
<style scoped>
.margins-left-right {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
</style>
<script setup>
import { ref } from 'vue';
import useTours from '@/composables/useTours';
import useHeader from '@/composables/useHeader';

import { useIonRouter, onIonViewDidEnter } from '@ionic/vue'
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonList, IonListHeader, IonItem, IonLabel, IonCheckbox, IonButton } from '@ionic/vue';

const tours = useTours();
const tour = tours.tour;
const groups = ref({});
const router = useIonRouter();
const header = useHeader();


onIonViewDidEnter(() => {
    header.setText('Add Options');
    console.log('Select Options', tour);
});

function createGroups() {
    groups.value = tour.options.reduce((a, v) => {
        if (v.option_name !== 'Single Supplement') {
            if (!a[v.group_title]) {
                a[v.group_title] = {
                    title: v.group_title,
                    options: []
                };
            }
            a[v.group_title].options.push(v);
        }
        return a;
    }, {});
}

function optionChanged(event, id) {
    const option = tour.options.find(o => o.option_id === id);

    option.checked = event.detail.checked;
    total();
}
createGroups();

function total() {
    let rval = 0;

    tour.options.forEach(option => {
        if (option.checked) {
            //
            // TODO verify usage of possible multiple selections
            //
            let price = Number(getOptionPrice(option));
            if (option.group === '2') {
                rval += price * tour.rooms.length;
            } else if (option.group === '4') {
                rval += price;
            } else {
                rval += price * tour.travellers.length;
            }
            console.log(price, rval)
        }
    });

    tour.optionsPrice = rval * 100;
}

function getOptionPrice(option) {
    if (Array.isArray(option.quantities_and_prices.selection)) {
        return option.quantities_and_prices.selection[0].price
    } else {
        return option.quantities_and_prices.selection.price
    }
}
function formatAmount(amount) {
    return (amount / 100).toFixed(2);
}
function customerDetails() {
    router.push('/CustomerDetails');
}
</script>
  